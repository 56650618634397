$icomoon-font-family: "delahaye" !default;
$icomoon-font-path: "fonts" !default;

$icon-factory: "\e925";
$icon-taxon-masc: "\e921";
$icon-french-fab: "\e924";
$icon-times: "\e920";
$icon-fax: "\e91e";
$icon-cart-alt: "\e91f";
$icon-sync: "\e922";
$icon-check-o: "\e923";
$icon-taxon-manu: "\e91d";
$icon-taxon-abs: "\e900";
$icon-taxon-as: "\e901";
$icon-taxon-bc: "\e902";
$icon-taxon-be: "\e903";
$icon-catalogue: "\e904";
$icon-award: "\e905";
$icon-shipping-fast: "\e906";
$icon-taxon-chaf: "\e907";
$icon-taxon-cu: "\e908";
$icon-taxon-dp: "\e909";
$icon-taxon-esi: "\e90a";
$icon-french-manufacturer: "\e90b";
$icon-chevron-right: "\e90c";
$icon-chevron-left: "\e91c";
$icon-rooster: "\e90d";
$icon-taxon-gr: "\e90e";
$icon-instagram: "\e90f";
$icon-taxon-kt: "\e910";
$icon-linkedin: "\e911";
$icon-scratch: "\e912";
$icon-taxon-nt: "\e913";
$icon-taxon-ob: "\e914";
$icon-cart: "\e915";
$icon-user: "\e916";
$icon-taxon-ri: "\e917";
$icon-taxon-rs: "\e918";
$icon-customer-satisfaction: "\e919";
$icon-phone: "\e91a";
$icon-youtube: "\e91b";

