.steps {
    box-shadow: $box-shadow;

    @include media-breakpoint-up(lg) {
        display: flex;
        flex-wrap: wrap;
    }

    .steps-item {
        display: flex;
        flex-grow: 1;
        flex-basis: 0;
        background: $light;
        border-radius: $border-radius;
        padding: 1.2rem 1.8rem;

        &:hover {
            text-decoration: none;
        }

        &.active {
            background: $primary;

            .steps-icon,
            .steps-text {
                color: rgba(255, 255, 255, 1);
            }

            .steps-title {
                color: #fff;
            }
        }

        &.completed {
            background: $white;

            .steps-icon,
            .steps-text {
                color: $text-muted;
            }

            .steps-title {
                color: $text-muted;
            }
        }

        &.disabled {
            cursor: auto;
            pointer-events: none;
        }
    }

    .steps-title {
        text-transform: uppercase;
        font-weight: $font-weight-bolder;
    }

    .steps-icon {
        font-size: 2rem;
        padding-right: 1rem;
        color: $dark;
    }

    .steps-text {
        color: $dark;
        font-size: $font-size-sm;
        line-height: 1.5;
    }
}
