@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?o75rij');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?o75rij#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?o75rij') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?o75rij') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?o75rij##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-factory {
  &:before {
    content: $icon-factory; 
  }
}
.icon-taxon-masc {
  &:before {
    content: $icon-taxon-masc; 
  }
}
.icon-french-fab {
  &:before {
    content: $icon-french-fab; 
  }
}
.icon-times {
  &:before {
    content: $icon-times; 
  }
}
.icon-fax {
  &:before {
    content: $icon-fax; 
  }
}
.icon-cart-alt {
  &:before {
    content: $icon-cart-alt; 
  }
}
.icon-sync {
  &:before {
    content: $icon-sync; 
  }
}
.icon-check-o {
  &:before {
    content: $icon-check-o; 
  }
}
.icon-taxon-manu {
  &:before {
    content: $icon-taxon-manu; 
  }
}
.icon-taxon-abs {
  &:before {
    content: $icon-taxon-abs; 
  }
}
.icon-taxon-as {
  &:before {
    content: $icon-taxon-as; 
  }
}
.icon-taxon-bc {
  &:before {
    content: $icon-taxon-bc; 
  }
}
.icon-taxon-be {
  &:before {
    content: $icon-taxon-be; 
  }
}
.icon-catalogue {
  &:before {
    content: $icon-catalogue; 
  }
}
.icon-award {
  &:before {
    content: $icon-award; 
  }
}
.icon-shipping-fast {
  &:before {
    content: $icon-shipping-fast; 
  }
}
.icon-taxon-chaf {
  &:before {
    content: $icon-taxon-chaf; 
  }
}
.icon-taxon-cu {
  &:before {
    content: $icon-taxon-cu; 
  }
}
.icon-taxon-dp {
  &:before {
    content: $icon-taxon-dp; 
  }
}
.icon-taxon-esi {
  &:before {
    content: $icon-taxon-esi; 
  }
}
.icon-french-manufacturer {
  &:before {
    content: $icon-french-manufacturer; 
  }
}
.icon-chevron-right {
  &:before {
    content: $icon-chevron-right; 
  }
}
.icon-chevron-left {
  &:before {
    content: $icon-chevron-left; 
  }
}
.icon-rooster {
  &:before {
    content: $icon-rooster; 
  }
}
.icon-taxon-gr {
  &:before {
    content: $icon-taxon-gr; 
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram; 
  }
}
.icon-taxon-kt {
  &:before {
    content: $icon-taxon-kt; 
  }
}
.icon-linkedin {
  &:before {
    content: $icon-linkedin; 
  }
}
.icon-scratch {
  &:before {
    content: $icon-scratch; 
  }
}
.icon-taxon-nt {
  &:before {
    content: $icon-taxon-nt; 
  }
}
.icon-taxon-ob {
  &:before {
    content: $icon-taxon-ob; 
  }
}
.icon-cart {
  &:before {
    content: $icon-cart; 
  }
}
.icon-user {
  &:before {
    content: $icon-user; 
  }
}
.icon-taxon-ri {
  &:before {
    content: $icon-taxon-ri; 
  }
}
.icon-taxon-rs {
  &:before {
    content: $icon-taxon-rs; 
  }
}
.icon-customer-satisfaction {
  &:before {
    content: $icon-customer-satisfaction; 
  }
}
.icon-phone {
  &:before {
    content: $icon-phone; 
  }
}
.icon-youtube {
  &:before {
    content: $icon-youtube; 
  }
}

