// Body

body {
    line-height: 1.15rem;
}

.scratched-before,
.scratched-after {
    position: relative;
}
.scratched-before::before,
.scratched-after::after {
    content: "\e912";
    font-family: delahaye;
    position: absolute;
    z-index: 1;
    font-size: 7rem;
}
.scratched-before::before {
    left: 20%;
    top: -0.8rem;
}
.scratched-after::after {
    right: 20%;
    bottom: 0;
}

// Top navbar


.logo {
    width: 18.625rem;
    background-color: transparent;
    padding: 0;
    transition: $transition-base;

    //@media (max-width: 1350px) {
    //    width: 14.6rem;
    //}
    @include media-breakpoint-down(lg) {
        width: 9.375rem;
        background-color: $white;
        padding: 1px;
    }

    @include media-breakpoint-down(md) {
        width: 8.375rem;
        background-color: $white;
        padding: 1px;
    }
}
#main-navbar {
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    transition: $transition-base;

    &.ticking {
        padding-top: 0;
        padding-bottom: 0;
        background-color: #0e0f13;

        //.logo {
        //    width: 15.625rem;
        //}
    }

    @include media-breakpoint-down(md) {
        padding-top: 0;
        padding-bottom: 0.5rem;

        &.ticking {
            padding-top: 0;
            padding-bottom: 0;

            .logo {
                width: 8.375rem;
            }
        }
    }
}
#main-nav {
    //&.show {
    //    background-color: $yellow;
    //}

    a {
        transition: $transition-base;

        &.nav-link {
            text-transform: uppercase;
            color: #02F5D0;
            font-weight: $font-weight-bolder;
            font-size: $font-size-base * 0.875;

            &:hover, &.active {
                color: #02F5D0;
                //text-decoration: underline;
            }
        }
    }
}

.navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 1.2rem;
    padding-right: 1.2rem;

    @media (max-width: 1365px) {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

.customer-service-container {
    line-height: 1;
    color:$yellow;
}
.customer-service {
    text-transform: uppercase;
    font-size: 0.625rem;
    letter-spacing: 0.13rem;
}
.customer-service-number {
    font-weight: $font-weight-bolder;
    font-size: 1.25rem;
}

.locale-switch {
    > .dropdown {
        > button {
            &::after {
                display: none;
            }

            &.rounded-circle {
                padding: 0.85rem;
            }
        }
    }
}

// Header

#header {
    background-color: $yellow;
    padding-top: 8rem;
    padding-bottom: 5rem;
    margin-bottom: 5rem;

    [class^="icon-taxon-"],
    [class^="icon-cart-"],
    [class^="icon-user"],
    [class*=" icon-taxon-"] {
        font-size: 4rem;
    }

    address {
        font-size: 1.125rem;
        line-height: 1.5rem;
        font-weight: $font-weight-light;
    }
}

.product-header-name {
    h1 {
        font-size: 2.75rem;
    }
}

.headband {
    background-color: #0e0f13;
    padding-top: 10rem;
    padding-bottom: 0.625rem;
    //margin-bottom: 5rem;
    text-transform: uppercase;
    color: #2d2d2d;
    font-weight: 900;
    font-size: 0.875rem;
    z-index: 1024;
    width: 100%;

    .container {
        border: 2px solid #fff;
    }
}

// Breadcrumb

.breadcrumb {
    font-weight: $font-weight-bold;
    font-size: 0.875rem;

    > .breadcrumb-item {
        > a {
            color: $white;

            &:hover {
                color: $dark;
            }
        }
    }
}

// Content

//.main-content > :first-child:not(nav):not(.nav) {
//    margin-top: map-get($spacers, 4);
//}

// Footer

#footer {
    background-size: cover;
    color:white;
    .bg-primary{
        background-color:black !important;
    }
    h6 {
        border-bottom: 1px solid white;
        padding-bottom: 0.4rem;
        color:$yellow;
    }
    .list-item, li{
        a{
            color:white !important;
            &.btn-light{
                color:$yellow !important;
            }
        }
    }
    .scratched-before::before {
        color: $white;
        left: 51%;
        margin-top: -0.55rem;
    }

    .btn {
        &.rounded-circle {
            padding: 0.6rem;
            letter-spacing: 0;

            [class^="icon-"],
            [class*=" icon-"] {
                font-size: 1.1rem;
            }
        }
    }

    a {
        transition: $transition-base;

        &:hover {
            color: $white;
        }
    }
}

// Loader

.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99999;
    display: none;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, .7);
}

.loadable {
    &.loading {
        .loading-overlay {
            display: flex;
        }
    }
}

// Form

label {
    &.required::after {
        &:not(.radio-custom, .checkbox-custom) {
            content: '*';
            display: inline-block;
            padding-left: 0.25rem;
            color: $danger;
            left: auto;
        }
    }

    a {
        text-decoration: underline;
    }
}

.radio-custom::before,
.radio-custom::after {
    top: 0.35rem;
}

.custom-file-label::after {
    background-color: #C9C9C9;
}

// Button

.btn {
    text-transform: uppercase;
    letter-spacing: 0.15rem;
    color: $white;

    &.rounded-circle {
        padding: 0.6rem;
        letter-spacing: 0;

        [class^="icon-"],
        [class*=" icon-"] {
            font-size: 1.5rem;
            color:#32f7d9;

            //@include media-breakpoint-down(sm) {
            //    font-size: $font-size-sm * 1.2;
            //}
        }
        [class*=" flag"] {
            font-size: 1.5rem;
            margin: 0 auto;

            //@include media-breakpoint-down(sm) {
            //    font-size: $font-size-sm * 1.2;
            //
            //    padding: 0rem;
            //}
        }
    }
}

.btn-link {
    color: $dark;
}

.btn-light {
    color: $dark;

    &:hover,
    &:focus {
        color: $white;
        background-color: $dark;
        border-color: $dark;
    }
}

.search-form {
    .form-group {
        margin-bottom: 0;
    }

    .btn {
        padding-top: 0.7rem;
        padding-bottom: 0.7rem;

        [class^="fa "],
        [class*=" fa-"] {
            font-size: 1.2rem;
        }
        [class^="icon-"],
        [class*=" icon-"] {
            font-size: 1.15rem;
        }
    }
}


// Typograpgy

h1, .h1 {
    //text-transform: uppercase;
    font-weight: $font-weight-bolder;

    @include media-breakpoint-down(sm) {
        font-size: $font-size-sm * 2.5;
    }
}

h2, .h2 {
    //text-transform: uppercase;
    font-weight: $font-weight-bolder;

    &.light {
        color: $light;
    }
}

p {
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: $font-weight-light;
}

i {
    font-style: italic;
    font-weight: inherit;
}

.subhead {
    font-family: $font-family-cursive;
    color: $yellow;
    @include font-size($subhead-font-size);

    &.light {
        color: $light;
    }
}

// Components

.card {
    box-shadow: $box-shadow;
    border: none;

    .card-header {
        background-color: #f4f4f4;
    }

    &.card-product {

        > .card-body {
            transition: $transition-base;

            .card-title {
                font-size: 1.125rem;
                font-weight: $font-weight-bolder;
                //text-transform: uppercase;

                &::before {
                    content: ' ';
                    border-top: 2px solid $dark;
                    width: 3rem;
                    display: block;
                    padding: 0.4rem 0;
                }
            }

            .card-subtitle {
                font-weight: $font-weight-bold;
                color: $yellow;
                text-transform: uppercase;
            }

            .card-text {
                font-size: 0.875rem;
                font-weight: $font-weight-light;
            }
        }

        > .card-footer {
            background-color: $white;
            transition: $transition-base;

            .card-price {
                font-weight: $font-weight-light;
                font-size: 1.125rem;
                padding-left: $card-spacer-x;

                sup {
                    font-size: 0.5rem;
                    top: -0.35rem;
                }
            }

            .card-link {
                color: $white;
                padding: 0;

                .card-link-label {
                    font-size: 0.6875rem;
                    text-transform: uppercase;
                    background-color: $dark;
                    visibility:hidden;
                    opacity:0;
                    transition:visibility 0.3s ease,opacity 0.3s ease;
                }

                .card-link-icon {
                    background-color: $dark;
                    //font-size: 2rem;
                }

                &:hover {
                    .card-link-label {
                        visibility: visible;
                        opacity:1;
                    }
                }
            }
        }

        > a {
            //padding-top: 1rem;
            text-align: center;

            > img {
                max-width: 260px;
            }

            > .card-img-overlay {
                transition: $transition-base;
            }
        }

        &:hover {
            > .card-body {
                background-color: $card-bg-hover;

                .card-title, .card-subtitle, .card-text {
                    color: $white !important;
                }

                .card-title::before {
                    border-top-color: $white;
                }
            }

            > a {
                position: relative;

                > .card-img-overlay {
                    background-color: $card-bg-hover;
                }
            }

            > .card-footer {
                background-color: $card-bg-hover;

                .card-price {
                    color: $white;
                }
            }
        }
    }
}


#taxon-vertical-menu {
    background-color: $white;
    box-shadow: $box-shadow;

    > .nav-item {
        border-bottom: 1px solid #EFEFEF;

        > .nav-link {
            font-size: 0.875rem;
            transition: $transition-base;

            &:hover,
            &.active,
            &[aria-expanded="true"] {
                background-color: $dark;
                color: $white;
                font-weight: $font-weight-bolder;
            }
        }

        > .collapse {
            background-color: $dark;
            color: $white;
        }

        .nav-item {
            .nav-link {
                transition: $transition-base;
                font-size: 0.8125rem;
                color: $white;
                padding: 0.4rem 1rem;

                &:hover,
                &.active,
                &[aria-expanded="true"] {
                    color: $yellow;
                }
            }
        }

        [aria-expanded="false"] {
            .fa-chevron-up {
                transform: scale(0.9) rotate(-180deg);
            }
        }
    }
}

.taxon-icon-link {
    transition: $transition-base;

    &:hover {
        background-color: #2d2d2d;
        color: #ffffff;
        text-decoration: none;
        box-shadow: $box-shadow;

        .taxon-icon {
            display: none;

            &.taxon-icon-hover {
                display: block;
            }
        }
    }

    .taxon-icon {
        width: 5.625rem;
        margin: 0 auto;

        &.taxon-icon-hover {
            display: none;
        }
    }
}

// Cart widget

.cart-widget {
    .badge {
        position: absolute;
        transform: none;
        top: -0.8rem;
        right: -0.6rem;
    }

    > .btn-group {
        > a.rounded-circle {
            &[aria-expanded="true"] {
                border-bottom-left-radius: 0 !important;
                border-bottom-right-radius: 0 !important;
            }
        }

        > .dropdown-menu {
            margin-top: 0;
            background-color: $dark;
            border-color: $dark;
            color: $white;
        }
    }
}

// Background

[data-background] {
    position: relative;
    background-position: center;
    background-size: cover;
}

.contain[data-background] {
    background-size: contain;
    background-repeat: no-repeat;
}


// Product

.product {
    .product-image {
        box-shadow: $box-shadow;
        margin-top: -9.3rem;
    }
    .product-thumb {
        box-shadow: $box-shadow;
    }
    .product-name {
        font-size: 1.875rem;
        line-height: 2rem;
        font-weight: $font-weight-bolder;
        //text-transform: uppercase;
    }

    .product-code {
        font-weight: $font-weight-bold;
        color: $yellow;
        text-transform: uppercase;
    }

    .product-description-title {
        font-size: 0.875rem;
        font-weight: bold;
        text-transform: uppercase;
    }

    .product-description {
        font-size: 1rem;
    }

    .product-price {
        font-weight: $font-weight-bolder;
        font-size: 1.625rem;

        sup {
            font-size: 0.9rem;
            top: -0.6rem;
        }
    }

    .product-tabs {
        .nav-tabs {
            margin: 0;
            border-bottom: 1px solid #C8C8C8;

            .nav-link {
                color: #C8C8C8;
                font-size: 1.125rem;
                font-weight: $font-weight-bolder;
                text-transform: uppercase;
            }

            .nav-link:hover,
            .nav-link:focus,
            .nav-link.active {
                color: $dark;
                background-color: transparent;
                border: none;
                border-bottom: 2px solid $dark;
            }
        }

        .tab-content {
            font-size: 1.125rem;
            font-weight: $font-weight-light;
        }
    }
}

// Pagination

.pagination {
    display: flex;
    align-items: center;

    .page-item {
        margin: 0 0.25rem;
        text-align: center;

        .page-link {
            border-radius: 50%;
            width: 2.5rem;
            height: 2.5rem;
            text-align: center;
        }

        &.prev {
            margin: 0 0.25rem 0 0;

            .page-link {
                width: 3.5rem;
                height: 3.5rem;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &.next {
            margin: 0 0 0 0.25rem;

            .page-link {
                width: 3.5rem;
                height: 3.5rem;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

// Contact Form

.contact-form-container {
    position: absolute;
    width: 100%;
    top: 20rem;
    z-index: 1;

    .scratched-before::before {
        left: 3rem;
    }

    > h2 {
        padding: 0 3rem;
    }

    @include media-breakpoint-down(md) {
        position: static;
        top: auto;
    }
}

// News

.articles {
    .article-item {
        .article-image-container {
            height: 31.25rem;
            padding-left: 0;
            padding-right: 1.125rem;
            background: rgba(45,45,45,0);
            background: -moz-linear-gradient(top, rgba(45,45,45,0) 5%, rgba(45,45,45,1) 5%, rgba(45,45,45,1) 95%, rgba(45,45,45,0) 95%);
            background: -webkit-gradient(left top, left bottom, color-stop(5%, rgba(45,45,45,0)), color-stop(5%, rgba(45,45,45,1)), color-stop(95%, rgba(45,45,45,1)), color-stop(95%, rgba(45,45,45,0)));
            background: -webkit-linear-gradient(top, rgba(45,45,45,0) 5%, rgba(45,45,45,0.1) 5%, rgba(45,45,45,0.1) 95%, rgba(45,45,45,0) 95%);
            background: -o-linear-gradient(top, rgba(45,45,45,0) 5%, rgba(45,45,45,0.1) 5%, rgba(45,45,45,0.1) 95%, rgba(45,45,45,0) 95%);
            background: -ms-linear-gradient(top, rgba(45,45,45,0) 5%, rgba(45,45,45,1) 5%, rgba(45,45,45,0.1) 95%, rgba(45,45,45,0) 95%);
            background: linear-gradient(to bottom, rgba(45,45,45,0) 5%, rgba(45,45,45,1) 5%, rgba(45,45,45,1) 95%, rgba(45,45,45,0) 95%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2d2d2d', endColorstr='#2d2d2d', GradientType=0 );

            .article-image {
                box-shadow: $box-shadow;
            }

            @include media-breakpoint-down(md) {
                height: 20rem;
            }
        }
        .article-content {
            h2 {
                font-weight: $font-weight-bolder;
                font-size: 1.5rem;
                //text-transform: uppercase;
            }
        }
    }
}
.vertical-offset {
    top: auto;

    @include media-breakpoint-up(lg) {
        top: 6.25rem;
    }
}
.article {
    .article-image {
        box-shadow: $box-shadow;
        margin-top: -4.3rem;
    }
}


.taxon-video {
    width: 100%;

    @media (min-width: 992px) {
        width: 50%;
    }
}

.taxon-static-text {
    text-align: justify;
    p, ul li {
        font-size: 1rem;
        line-height: 1.375rem;
        font-weight: 300;
        &:last-child {
            margin: 0;
        }
    }

    b, .font-weight-bold {
        font-weight: 500 !important;
    }
}
.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
