
// Page catalog
.list-catalogs{
    display: flex;
    justify-content: space-around;
    align-items: center;

    .block-catalog{
        position: relative;
        width: 38%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .title-catalog{
            font-size: 1.125rem;
            font-family: "Lato", sans-serif;
            color: $gray-900;
            font-weight: 900;
            text-transform: uppercase;
            text-align: center;
        }
        .image-catalog{

        }
        .link-catalog{
            position: absolute;
            top: 0;right: 0;bottom: 0;left: 0;
        }
    }
}
